.about-container{
    text-align: center;
    height: 95vh;
    margin: 0px;
    color:black;
    overflow:scroll;
}

.about-container > h1{
    color: #C9A885;

}

.about-container::-webkit-scrollbar {
    display: none;
}

.about-container > h1{
    font-size:4em;
     
}

.lg-img{
    height:250px;
    width:250px;
    border-radius: 15px;
    
    
}

.story-box{
    
    border-radius: 15px;
    margin:10px auto;
    font-size: 24px;
    padding: 10px;
    width:80%;    
    background-color:rgba(255, 255, 255, 0.154);
    color: #C9A885;
    border: solid 2px #C9A885;
    
    
}

.story-box-inner{
    background-color: #333;
    margin:10px;
    height:100%;
    font-size: 30px;
    text-align: center;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius:15px;
    /* width:95% */
}

.story-box > h3{
    font-size:2em;
    margin:0px;
    
    
}

.small-img{
    height:100px;
    width:100px;
    background-color: white;
    margin:0 auto;
    border-radius:15px;
}

.img-box{
    display: flex;
    justify-content: space-evenly;
    margin:50px;
}

.small-img-container{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap:5px;
    justify-items:end;

}

.story-box > p {

    color: #C9A885;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;

    
    
}

@media (max-width: 1050px) {
    .about-container{
        margin:0 auto;
        height: 90vh;
   
    }
}

@media (max-width: 500px) {
    .about-container{
        margin:0 auto;
        height: 90vh;
    }

    .about-container > h1{
        margin:5px 0px; 
        font-size: 2em; 
    }

    .story-box > h3{
        font-size:1em;
        margin:0;
        
    }

    .small-img{
        height:50px;
        width:50px;
    }

    .small-img-container{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-gap:5px;
        row-gap: 5px;
        justify-items:end;
        align-self: center;
    
    }
}
