.home-container{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-self: center;
    color: #C9A885;
    overflow:scroll;
}

.home-container::-webkit-scrollbar {
    display: none;
}

.name{
    font-size:6em;
    margin: 0px;
    
}

.profession{
    font-size:3em;
    margin: 0px;
}

.portfolio{
    font-size:2em;
    margin: 0px; 
}

.underline{
    height: 2px;
    width: 50%;
    margin: 5px auto;
    background-color: #C9A885;
}

.image-container-home{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 10px 25%;
}

.small-img-home{
    height:70px;
    width:70px;
    background-color: white;
    margin:0 auto;
    border-radius:15px;
}

@media(max-width: 500px){
    .small-img-home{
        margin:10px auto;
        height:50px;
        width:50px;
    }
}

@media(max-width: 1050px){
    .small-img-home{
        margin:10px 5px;
        height:50px;
        width:50px;
    }
}