.images-wrapper{
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 90%;
    margin: 0px 0px;
}
.images-wrapper-carousel{
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 40%;
    margin: 15px auto;
    
}
.images-wrapper-carousel-budget{
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 40%;
    margin: 15px auto;
    
}

.images-wrapper-carousel-try{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 20%;
    margin: 15px auto;
    
}

.images-phone{
    display: none;
    /* flex-direction: row;
    flex-wrap: wrap; */
}



.images-wrapper > a{
    color: #C9A885;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border:#C9A885 1px solid;
    border-radius: 15px;
    background-color: #333;
    margin:2px;
}
    
.images-wrapper > a:hover{
    background-color:#C9A885;
    color: black;
}

.description{
    color: #C9A885;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    background-color: #333;
    margin:5px auto;;
    font-size: 22px;            
    text-align: justify;
    box-sizing: border-box;
    border-radius: 15px;
}

.description-lg{
    color: #C9A885;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    background-color: #333;
    margin:0px auto;;
    font-size: 22px;            
    text-align: center;
    box-sizing: border-box;
    border-radius: 15px;
}

.description-lg > a{
    text-decoration: none;
    color: #C9A885;
    border:#C9A885 1px solid;
    border-radius: 15px;
    padding: 5px;
    margin: 0px 5px;
}

.description-lg > a:hover{
    background-color:#C9A885;
    color: black;
}



.images-wrapper > h1{
    font-size: 2em;
    margin: 5px;
}

.image-container{
    display: flex;
    justify-self: center;
    height:fit-content;
    width:100%;
    margin: 10px auto;
    box-sizing: border-box;
}

.image-container-try{
    display: flex;
    justify-self: center;
    max-height:auto;
    width:35%;
    margin: 10px auto;
    box-sizing: border-box;
}

.image-container > img{
    width:90%;
    margin: 0px auto;
}

.image-container-try > img{
    width:100%;
    margin: 0px auto;
}

.single-project{
    background-color:rgba(255, 255, 255, 0.154);
    color: #C9A885;
    border: solid 2px #C9A885;
    justify-items:center;
    margin:10px;
    border-radius:15px;
    height: 88vh;
    padding:10px;
    overflow:scroll;
    

}
.single-project > h1{
    text-align:center;
    font-size: 3em;
}

.single-project::-webkit-scrollbar {
    display: none;
}

.mvp-container{   
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 15px auto;  
    background-color: #333;
    border-radius:15px;    
}

.mvp-container > ul > li{
    font-weight:bold;
    font-size:large;
}

.mvp{
    text-align:center;
    margin:3px auto;

}

.phone-page{
    display:none;
}

@media (max-width: 1050px) {
  .images-wrapper > h1{
        font-size: 1.5em;
        margin: 2px;
    }
    .images-phone > img{
        width: 40%;
        height:30%;
        margin: 5px;
    
    }

    .images-wrapper-carousel-budget{
        display: flex;
        flex-direction: column;
        height: 60%;
        width: 70%;
        margin: 15px auto;
        
    }
    .images-wrapper-carousel{
        display: flex;
        flex-direction: column;
        height: 60%;
        width: 60%;
        margin: 15px auto;
        
    }
    
   
}

@media (max-width: 900px) {
    .images-wrapper > h1{
          font-size: 1.5em;
          margin: 2px;
      }
      .images-phone > img{
          width: 40%;
          height:30%;
          margin: 5px;
      
      }
  
      .images-wrapper-carousel-budget{
          display: flex;
          flex-direction: column;
          height: 40%;
          width: 80%;
          margin: 15px auto;
          
      }

      .images-wrapper-carousel{
        display: flex;
        flex-direction: column;
        height: 30%;
        width: 60%;
        margin: 15px auto;
        
    }
      
     
  }

  @media (max-width: 820px){
    .images-wrapper-carousel-budget{
        display: flex;
        flex-direction: column;
        height: 85%;
        width: 60%;
        margin: 15px auto;
        
    }

    .images-wrapper-carousel{
      display: flex;
      flex-direction: column;
      height: 60%;
      width: 40%;
      margin: 15px auto;
    }
  }

  @media (max-width: 770px){
    .images-wrapper-carousel-budget{
        display: flex;
        flex-direction: column;
        height: 35%;
        width: 60%;
        margin: 15px auto;
        
    }

    .images-wrapper-carousel{
      display: flex;
      flex-direction: column;
      height: 35%;
      width: 70%;
      margin: 15px auto;
    }
  }



@media (max-width:500px){
    .mvp-container > ul > li {
        font-size:small;
    }
    .mvp{
        font-size:1em;
    }
    .single-project{
        width:auto;
        padding:2px;
        height:auto;
        margin-left:20px;
    }
    .description-lg{
         font-size: small;
         display:flex;
         flex-direction:column;
    }

    .description-lg > a{
        margin:5px auto;
    }

    .full-page{
        display: none;
    }

    .phone-page{
        display:flex;

    }
    .images-wrapper-carousel-try{
        display: flex;
        flex-direction: column;
        height: auto;
        width: 50%;
        margin: 15px auto;
        
    } 
    .images-wrapper-carousel-budget{
        display: none;
        
    }

    .images-wrapper-carousel{
        display: none;
        
    }
    .images-phone{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px auto;
        justify-content: center;
    }
   

    

    
}





