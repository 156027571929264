.nav-bar{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow:hidden;
    padding:10px;
    background-color: #333;
}

@media (max-width:1000px){
    .nav-bar{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows:1fr 1fr;
       
    }
}
@media (max-width:500px){
    .nav-bar{
        width:100vw;       
    }
}

.nav-bar > a{
    color: #C9A885;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border:#C9A885 1px solid;
    border-radius: 15px;
    background-color: #333;
    margin:2px;
}

.nav-bar >a:hover{
    background-color:#C9A885;
    color: black;
}


  
 

