.contact-container{
    display:flex;
    flex-direction:column;    
    justify-items: center;
    align-items: center;
    height: auto;
    width:auto;
    color: black;
    overflow:scroll;
    margin: 10px auto;
    height:90vh;

}
.contact-container > section > a {
    text-decoration: none;
    color: black;
    font-size:2em;
    
}

.contact-container > h1{
    color: #C9A885;
}
.contact-container > h1 {
    font-size:4em;    
}

.contact-container > section > p {
    font-size:2em; 
       
}

.contact-container::-webkit-scrollbar {
    display: none;
}

.questions {
    /* background-color:rgba(95, 158, 160, 0.681);  */
    margin: 20px auto;
    border-radius:15px; 
    text-align:center;
    width:90%;
    padding:15px;
    height: auto;
    background-color:rgba(255, 255, 255, 0.154);
    color: #C9A885;
    border: solid 2px #C9A885;
}

.questions > p{
    color: #C9A885;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    background-color: #333;
    margin: auto;;
    height:100%;
    font-size: 26px;
    text-align: center;
    padding: 0px 15px;
    box-sizing: border-box;
    border-radius:15px;
}

.contact-links{
    background-color:rgba(255, 255, 255, 0.154);
    border: solid 2px #C9A885;
    margin: 30px auto;
    border-radius:15px; 
    text-align:center;
    width:90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding:10px;
    
    
}

.contact-links > p > a {
    text-decoration:none;
    color: black;
}

.contact-links > p > a:hover{
    background-color: #C9A885;
    color: black;
}

.link-image{
    height: 200px;
    width: 200px;
    border-radius: 15px;
    margin: 5px auto;
}

@media (max-width:1000px){
    .contact-container{
        width:100vw; 
        height: 80 vh;
       
    }
    .footer{
        display:none;
    }
    .link-image{
        height: 100px;
        width: 100px;
        border-radius: 15px;
        margin: 5px auto;
    }
}
@media (max-width:500px){
    .contact-container{
        width:100vw; 
        height: 60 vh;      
    }
    .footer{
        display:none;
    }
    .link-image{
        height: 50px;
        width: 50px;
        border-radius: 15px;
        margin: 5px auto;
    }
}