.project-container{
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 3fr;
    justify-items: center;
    align-items: center;
    height: 90vh;
    width:auto;
    font-size:20px;
    overflow:scroll;
    color: #C9A885;
    padding: 10px;
}

.project-container > h1 {
    font-size:4em;
}

.project-container::-webkit-scrollbar {
    display: none;
}

.presentation-container{
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly; */
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 63vh;
    width: 100%;
    margin: 0 auto;
}


.presentation-box{
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    text-align: center;
    margin: 0 10px;
    background-color:rgba(255, 255, 255, 0.154);
    color: #C9A885;
    border-radius: 15px;
    border: solid 2px #C9A885;
    overflow:scroll;
}

.presentation-box::-webkit-scrollbar {
    display: none;
}

@media (max-width: 1050px) {
    .project-container > h1{
          font-size: 3em;
          margin: 2px;
          padding: 5px;
      }
  }

@media (max-width: 500px){
    .presentation-container{
        display:flex;
        flex-direction:column;
        height:auto;
    
    }
    .presentation-box{
        margin:15px;
        height:150px;
    }
    .image-container> img{
        display:none;
    }
    .image-container-try > img{
        display:none;
    }
    .description{
        display:none;
    }
    .project-container > h1{
        margin: 0;
        font-size:2em;
    }

    .project-container {
        grid-template-rows:auto auto;
        width:100%;
    }
    
   
}

